import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faBars } from '@fortawesome/free-solid-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { toast } from 'react-toastify';
import styles from './Navbar.module.css';
import { UserContext } from './UserContext';

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const isLoggedIn = !!user;
  const isAdmin = user?.is_admin === 1;

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    setUser(null);
    toast.success('Logout successful!');
    navigate('/');
  };

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleMobileLinkClick = () => {
    setMobileMenuOpen(false);
  };

  return (
    <div className={styles.navbarContainer}>
      <div className={styles.desktopTitle}>Trendy Nerds</div>
      <nav className={`${styles.navbar} ${isAdmin ? styles.adminView : ''}`}>
        <div className={styles.navbarTitle}>Trendy Nerds</div>
        <FontAwesomeIcon icon={faBars} className={styles.burgerIcon} onClick={toggleMobileMenu} />
        <ul className={`${styles.navList} ${mobileMenuOpen ? styles.mobileMenuOpen : ''}`}>
          <li className={styles.navItem}>
            <Link className={styles.navLink} to="/" onClick={handleMobileLinkClick}>
              Main
            </Link>
          </li>
          <li
            className={styles.navItem}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <span className={styles.navLink}>
              Tops <FontAwesomeIcon icon={faCaretDown} className={styles.navIcon} />
            </span>
            {dropdownOpen && (
              <ul className={styles.dropdownMenu}>
                <li className={styles.dropdownItem}>
                  <Link className={styles.navLink} to="/leaderboard/trendy-factory" onClick={handleMobileLinkClick}>
                    Trendy Factory
                  </Link>
                </li>
                <li className={styles.dropdownItem}>
                  <Link className={styles.navLink} to="/leaderboard/trendy-match" onClick={handleMobileLinkClick}>
                    Trendy Match
                  </Link>
                </li>
              </ul>
            )}
          </li>
          {!isLoggedIn ? (
            <>
              <li className={styles.navItem}>
                <Link className={styles.navLink} to="/signup" onClick={handleMobileLinkClick}>
                  Sign Up
                </Link>
              </li>
              <li className={styles.navItem}>
                <Link className={styles.navLink} to="/login" onClick={handleMobileLinkClick}>
                  Login
                </Link>
              </li>
            </>
          ) : (
            <>
              <li className={styles.navItem}>
                <Link className={styles.navLink} to="/profile" onClick={handleMobileLinkClick}>
                  You
                </Link>
              </li>
              {isAdmin && (
                <li className={styles.navItem}>
                  <Link className={styles.navLink} to="/review" onClick={handleMobileLinkClick}>
                    Review
                  </Link>
                </li>
              )}
              <li className={styles.navItem}>
                <button className={`${styles.navLink} ${styles.logoutButton}`} onClick={() => { handleLogout(); handleMobileLinkClick(); }}>
                  Log Out
                </button>
              </li>
            </>
          )}
            <li className={`${styles.navItem} ${styles.mobileOnly}`}>
              <a href="https://discord.gg/ckaYFNn2c2" target="_blank" rel="noopener noreferrer" className={`${styles.navLink} ${styles.discordLink}`}>
                <FontAwesomeIcon icon={faDiscord} /> Discord
              </a>
            </li>
        </ul>
        <a href="https://discord.gg/ckaYFNn2c2" target="_blank" rel="noopener noreferrer" className={styles.discordIcon}>
          <FontAwesomeIcon icon={faDiscord} />
        </a>
      </nav>
    </div>
  );
};

export default Navbar;
