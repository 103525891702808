import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FaUserCircle } from 'react-icons/fa';
import styles from './Leaderboard.module.css';

const LeaderboardTrendyBoulder = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [timeUntilReset, setTimeUntilReset] = useState(null);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const limit = 20;

  const fetchLeaderboardData = async (newOffset = 0) => {
    try {
      const response = await fetch(`/api/leaderboard-trendy-boulder?limit=${limit}&offset=${newOffset}`);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Network response was not ok: ${errorData.message}`);
      }
      const data = await response.json();
      const filteredData = data.filter(entry => entry.daily_score > 0 && !isNaN(entry.daily_score));
      setLeaderboardData(prevData => [...prevData, ...filteredData]);
      setHasMore(filteredData.length === limit); // Check if we received enough data to load more
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
    }
  };

  const fetchResetTimer = async () => {
    try {
      const response = await fetch('/api/reset-timer');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setTimeUntilReset(data.timeUntilReset);
    } catch (error) {
      console.error('Error fetching reset timer:', error);
    }
  };

  useEffect(() => {
    fetchLeaderboardData(offset);
    fetchResetTimer();
  }, [offset]);

  useEffect(() => {
    if (timeUntilReset !== null) {
      const interval = setInterval(() => {
        setTimeUntilReset(prevTime => (prevTime > 1000 ? prevTime - 1000 : 0));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timeUntilReset]);

  const formatTimeUntilReset = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const handleLoadMore = () => {
    setOffset(prevOffset => prevOffset + limit);
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <div className={styles.leaderboardContainer}>
      <h2 className={styles.leaderboardTitle}>
        <FontAwesomeIcon icon={faTrophy} className={styles.titleIcon} /> 
        <div>
          Leaderboard for <br /> Trendy Boulder
        </div>
      </h2>
      {timeUntilReset !== null && (
        <div className={styles.resetTimer}>
          Reset in: {formatTimeUntilReset(timeUntilReset)}
        </div>
      )}
      {!isMobile ? (
        <table className={styles.leaderboardTable}>
          <thead>
            <tr>
              <th>Position</th>
              <th>Name</th>
              <th>Score</th>
              <th>Estimated Reward (SAND)</th>
            </tr>
          </thead>
          <tbody>
            {leaderboardData.length > 0 ? (
              leaderboardData.map((entry, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <div className={styles.leaderboardUser}>
                      {entry.avatar_url ? (
                        <img src={entry.avatar_url} alt="Avatar" className={styles.avatar} />
                      ) : (
                        <FaUserCircle className={styles.defaultAvatar} />
                      )}
                      {entry.name}
                    </div>
                  </td>
                  <td>{entry.daily_score}</td>
                  <td>{entry.estimatedReward}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">There are no submitted scores in the leaderboard since the last reset.</td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <div className={styles.leaderboardCardContainer}>
          {leaderboardData.length > 0 ? (
            leaderboardData.map((entry, index) => (
              <div key={index} className={styles.leaderboardCard}>
                <h3>Position: {index + 1}</h3>
                <p>
                  <span>Name:</span> {entry.name}
                </p>
                <p>
                  <span>Score:</span> {entry.daily_score}
                </p>
                <p>
                  <span>Estimated Reward (SAND):</span> {entry.estimatedReward}
                </p>
              </div>
            ))
          ) : (
            <div className={styles.noScoresMessage}>There are no submitted scores in the leaderboard since the last reset.</div>
          )}
        </div>
      )}
      {hasMore && (
        <button onClick={handleLoadMore} className={styles.loadMoreButton}>Load more</button>
      )}
    </div>
  );
};

export default LeaderboardTrendyBoulder;
