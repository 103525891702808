import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './Auth.module.css';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    const response = await fetch('/api/forgot-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (response.ok) {
      toast.success('We\'ve sent a password reset link to your email. Make sure to check your spam folder just in case!');
      setTimeout(() => {
        navigate('/login');
      }, 3000); // Redirects after 3 seconds
    } else {
      const error = await response.json();
      toast.error('Error: ' + error.message);
    }
  };

  return (
    <div className={styles.authContainer}>
      <ToastContainer />
      <h2 className={styles.authTitle}>Forgot Password</h2>
      <form onSubmit={handleForgotPassword} className={styles.authForm}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className={styles.authInput}
        />
        <button type="submit" className={styles.authButton}>Send Reset Link</button>
      </form>
    </div>
  );
};

export default ForgotPassword;
