import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './SubmissionModal.module.css';

const SubmissionModal = ({ isOpen, onRequestClose, game, email, tsbUid }) => {
  const [score, setScore] = useState('');
  const [image, setImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [showWarning, setShowWarning] = useState(false);

  const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB
  const [isSubmissionClosed, setIsSubmissionClosed] = useState(false);
  const [timeUntilReset, setTimeUntilReset] = useState(0);

  useEffect(() => {
    const checkSubmissionTime = () => {
      const now = new Date();
      const resetTime = new Date(now);
      resetTime.setUTCHours(21, 0, 0, 0); // Set the reset time to 00:00 GMT+3 (21:00 UTC)
  
      if (now > resetTime) {
        resetTime.setDate(resetTime.getDate() + 1); // Move to next day if we're past today's reset
      }
  
      const diffInHours = (resetTime - now) / (1000 * 60 * 60);
      setIsSubmissionClosed(diffInHours <= 1);
      setTimeUntilReset(resetTime - now);
    };
  
    checkSubmissionTime();
    const intervalId = setInterval(checkSubmissionTime, 60000); // Check every minute
  
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setScore('');
      setImage(null);
      setIsSubmitting(false);
      setMinutes('');
      setSeconds('');
      setYoutubeUrl('');
      setShowWarning(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (game === 'Trendy Match' && parseInt(score) >= 200 && !youtubeUrl) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [score, youtubeUrl, game]);

  useEffect(() => {
    return () => {
      // Clean up any pending state updates or async operations
      setIsSubmitting(false);
    };
  }, []);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedImage = e.target.files[0];
      if (selectedImage.size > MAX_IMAGE_SIZE) {
        toast.error('Image size exceeds 5MB limit. Please upload a smaller image.');
      } else {
        setImage(selectedImage);
      }
    }
  };

  const isWithinLastHourBeforeReset = () => {
    const now = new Date();
    const resetTime = new Date(now);
    resetTime.setUTCHours(21, 0, 0, 0); // Set the reset time to 00:00 GMT+3 (21:00 UTC)
  
    if (now > resetTime) {
      resetTime.setDate(resetTime.getDate() + 1); // Move to next day if we're past today's reset
    }
  
    const diffInHours = (resetTime - now) / (1000 * 60 * 60);
    return diffInHours <= 1;
  };

  const submitScore = async (formData) => {
    try {
      const response = await fetch('/api/submit-score', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      console.log('Submit response:', data);

      if (response.ok) {
        toast.success(formData.get('game') === 'Event' ? 'Event submission successful!' : 'Score submitted successfully!');
        return { success: true };
      } else {
        return { success: false, error: data.error || 'Failed to submit score.' };
      }
    } catch (error) {
      console.error('Error submitting score:', error);
      return { success: false, error: 'An error occurred while submitting. Please try again.' };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isWithinLastHourBeforeReset()) {
      toast.error('Submissions are not accepted within the last hour before the daily reset.');
      return;
    }

    setIsSubmitting(true);

    try {
      if (image && image.size > MAX_IMAGE_SIZE) {
        throw new Error('Image size exceeds 5MB limit. Please upload a smaller image.');
      }

      const formData = new FormData();
      formData.append('email', email);
      formData.append('tsb_uid', tsbUid);
      formData.append('game', game);
      formData.append('image', image);

      if (game !== 'Event') {
        if (game === 'Trendy Match' && parseInt(score) >= 200 && !youtubeUrl) {
          throw new Error('YouTube URL is required for scores of 200 or higher.');
        }

        const timeSeconds = parseInt(minutes) * 60 + parseInt(seconds);
        formData.append('score', score);
        formData.append('time_seconds', timeSeconds);

        if (game === 'Trendy Match') {
          formData.append('youtube_url', youtubeUrl);
        }
      }

      const result = await submitScore(formData);

      if (result.success) {
        onRequestClose();
      } else {
        toast.error(result.error);
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      toast.error(error.message || 'An error occurred while submitting. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModalClose = () => {
    if (!isSubmitting) {
      onRequestClose();
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal 
        isOpen={isOpen} 
        onRequestClose={handleModalClose}
        className={styles.modal} 
        overlayClassName={styles.overlay}
        shouldCloseOnOverlayClick={!isSubmitting}
        shouldCloseOnEsc={!isSubmitting}
      >
        {isSubmissionClosed && (
          <div className={styles.warningMessage}>
          Submissions are closed 1 hour before reset. They will reopen in {Math.floor(timeUntilReset / 60000)} minutes.
          </div>
        )}
        {game === 'Event' ? (
          <>
            <h2>Event Submission</h2>
            <div className={styles.modalContent}>
              <p>Together with ThePickaxeMaster, we invite you to embark on a quest in Trendy Factory and Misty Mountain. First, you must unlock the secret in the factory. Once you've done that, submit proof here. Then, visit Misty Mountain and complete the Pepperbell quest. Only those who complete both event quests will receive a limited edition Pepperbell Pickaxe!</p>
              <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.formGroup}>
                  <label>Upload Screenshot:</label>
                  <input type="file" accept="image/*" onChange={handleImageChange} required />
                  {image && <img src={URL.createObjectURL(image)} alt="Preview" className={styles.imagePreview} />}
                  <p className={styles.maxSizeInfo}>Max size: 5MB</p>
                </div>
              </form>
            </div>
            <div className={styles.submitButtonContainer}>
              <button type="submit" className={styles.submitButton} disabled={isSubmitting || isSubmissionClosed} onClick={handleSubmit}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </>
        ) : (
          <>
            <h2>Submit Your Score for {game}</h2>
            <div className={styles.modalContent}>
              <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.formGroup}>
                  <label>Email:</label>
                  <p>{email}</p>
                </div>
                <div className={styles.formGroup}>
                  <label>Username:</label>
                  <p>{tsbUid}</p>
                </div>
                <div className={styles.formGroup}>
                  <label>{game === 'Trendy Factory' ? 'Trendies' : 'Score'}:</label>
                  <input type="number" value={score} onChange={(e) => setScore(e.target.value)} required />
                </div>
                <div className={styles.formGroup}>
                  <label>Time (minutes and seconds):</label>
                  <div className={styles.timeInput}>
                    <input
                      type="number"
                      placeholder="Minutes"
                      value={minutes}
                      onChange={(e) => setMinutes(e.target.value)}
                      required
                    />
                    <input
                      type="number"
                      placeholder="Seconds"
                      value={seconds}
                      onChange={(e) => setSeconds(e.target.value)}
                      required
                    />
                  </div>
                </div>
                {game === 'Trendy Match' && (
                  <div className={styles.formGroup}>
                    <label>YouTube URL:</label>
                    <input
                      type="url"
                      className={styles.youtubeInput}
                      value={youtubeUrl}
                      onChange={(e) => setYoutubeUrl(e.target.value)}
                      required={parseInt(score) >= 200}
                    />
                    <p className={styles.youtubeRequirement}><span>YouTube/Vimeo/Drive/Transfer URL is <strong>required</strong> for scores of <strong>200 or higher</strong>.</span></p>
                  </div>
                )}
                <div className={styles.formGroup}>
                  <label>Upload Image:</label>
                  <input type="file" accept="image/*" onChange={handleImageChange} required />
                  {image && <img src={URL.createObjectURL(image)} alt="Preview" className={styles.imagePreview} />}
                  <p className={styles.maxSizeInfo}>Max size: 5MB</p>
                </div>
                <p className={styles.disclaimer}>
                  {game === 'Trendy Factory' ? (
                    "For all your daily submissions, you can receive a maximum of 12,000 score every 24 hours. This ensures that everyone has an equal opportunity to earn a significant share of the prize pool."
                  ) : (
                    <>
                      You can submit your score as many times as you want, but only the highest score will be added to your account every 24 hours. This helps you compound your best score every day.
                      <br />
                      <br />
                      Example: On Day 1, you get scores of 10, 50, and 120. Submit them all, but only the highest score (120) will appear on the leaderboard. On Day 2, you score 50 and 70; only the higher score (70) is calculated. Your total score now is Day 1 (120) + Day 2 (70). So you'll have to compete daily to maintain your high position.
                    </>
                  )}
                </p>
              </form>
            </div>
            <div className={styles.submitButtonContainer}>
              <button type="submit" className={styles.submitButton} disabled={isSubmitting || isSubmissionClosed} onClick={handleSubmit}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default SubmissionModal;
