// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Profile from './components/Profile';
import Review from './components/Review';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import LeaderboardTrendyFactory from './components/LeaderboardTrendyFactory';
import LeaderboardTrendyBoulder from './components/LeaderboardTrendyBoulder';
import LeaderboardTrendyMatch from './components/LeaderboardTrendyMatch'; // Import the new component
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserProvider } from './components/UserContext';

function App() {
  return (
    <UserProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/review" element={<Review />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/leaderboard/trendy-factory" element={<LeaderboardTrendyFactory />} />
          <Route path="/leaderboard/trendy-boulder" element={<LeaderboardTrendyBoulder/>} />
          <Route path="/leaderboard/trendy-match" element={<LeaderboardTrendyMatch />} /> {/* Add this route */}
        </Routes>
        <ToastContainer />
      </Router>
    </UserProvider>
  );
}

export default App;