import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './Review.module.css';

Modal.setAppElement('#root');

const Review = () => {
  const [scores, setScores] = useState([]);
  const [selectedTab, setSelectedTab] = useState('Pending');
  const [selectedImage, setSelectedImage] = useState(null);
  const [query, setQuery] = useState('');
  const [gameTitle, setGameTitle] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const PAGE_SIZE = 20;

  const fetchWithRefresh = useCallback(async (url, options, retryCount = 0) => {
    const MAX_RETRIES = 3;
    try {
      let response = await fetch(url, options);
      if (response.status === 401) {
        const responseData = await response.json();
        if (responseData.expired) {
          console.log('Token expired, attempting to refresh');
          const refreshToken = localStorage.getItem('refreshToken');
          if (!refreshToken) {
            console.error('No refresh token found');
            throw new Error('Authentication failed. Please log in again.');
          }
          const refreshResponse = await fetch('/token', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: refreshToken })
          });
          if (refreshResponse.ok) {
            const { accessToken, refreshToken: newRefreshToken } = await refreshResponse.json();
            if (!accessToken || !newRefreshToken) {
              throw new Error('Invalid response from server during token refresh');
            }
            localStorage.setItem('token', accessToken);
            localStorage.setItem('refreshToken', newRefreshToken);
            options.headers['Authorization'] = `Bearer ${accessToken}`;
            console.log('Tokens refreshed, retrying original request');
            response = await fetch(url, options);
          } else if (retryCount < MAX_RETRIES) {
            console.log(`Retry attempt ${retryCount + 1}`);
            return fetchWithRefresh(url, options, retryCount + 1);
          } else {
            console.error('Max retries reached');
            throw new Error('Unable to authenticate after multiple attempts. Please log in again.');
          }
        } else {
          throw new Error('Authentication failed');
        }
      }
      return response;
    } catch (error) {
      console.error('Error in fetchWithRefresh:', error);
      throw error;
    }
  }, []);
  
  const fetchScores = useCallback(async (status, query = '', gameTitle = '', page = 1) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in localStorage');
        toast.error('Please log in to view scores.');
        return;
      }
  
      console.log('Fetching scores:', { status, query, gameTitle, page });
  
      const statusQuery = status === 'Archive' ? 'Approved,Rejected' : status;
      const response = await fetchWithRefresh(`/api/review-scores/${statusQuery}?page=${page}&size=${PAGE_SIZE}&query=${query}&gameTitle=${gameTitle}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', response.status, errorText);
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }
  
      const data = await response.json();
      console.log('Received data:', data);
  
      if (page === 1) {
        setScores(data.scores);
      } else {
        setScores((prevScores) => [...prevScores, ...data.scores]);
      }
      setHasMore(data.scores.length === PAGE_SIZE);
      setPage(data.currentPage);
  
      if (data.scores.length === 0) {
        console.log('No scores received');
        toast.info('No scores available for the selected criteria.');
      }
    } catch (error) {
      console.error(`Error fetching ${status} scores:`, error);
      if (error.message.includes('Failed to fetch')) {
        toast.error('Network error. Please check your connection and try again.');
      } else if (error.message.includes('HTTP error!')) {
        toast.error(`Server error: ${error.message}`);
      } else if (error.message.includes('Authentication failed') || error.message.includes('Unable to authenticate')) {
        toast.error(error.message);
        // Optionally, redirect to login page here
        // history.push('/login');
      } else {
        toast.error('Failed to fetch scores. Please try again later.');
      }
    } finally {
      setIsLoading(false);
    }
  }, [fetchWithRefresh]);

  useEffect(() => {
    const handleReload = () => {
      setScores([]);
      setPage(1);
      setHasMore(true);
      fetchScores(selectedTab, query, gameTitle, 1);
    };

    window.addEventListener('focus', handleReload);

    return () => {
      window.removeEventListener('focus', handleReload);
    };
  }, [selectedTab, query, gameTitle]);

  useEffect(() => {
    setScores([]);
    setPage(1);
    setHasMore(true);
    fetchScores(selectedTab, query, gameTitle, 1);
  }, [selectedTab, fetchScores]);

  const handleStatusChange = async (id, status) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        return;
      }
      const response = await fetchWithRefresh('/api/update-score-status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id, status })
      });

      if (!response.ok) {
        throw new Error('Failed to update status');
      }

      setScores(scores.filter(score => score.id !== id));
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status. Please try again.');
    }
  };

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
  };

  const handleGameTitleChange = (e) => {
    setGameTitle(e.target.value);
  };

  const handleSearch = () => {
    setScores([]);
    setPage(1);
    setHasMore(true);
    fetchScores(selectedTab, query, gameTitle, 1);
  };

  const handleLoadMore = () => {
    if (hasMore && !isLoading) {
      fetchScores(selectedTab, query, gameTitle, page + 1);
    }
  };

  const handleApprove = async (score) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        return;
      }
      const { game_title, email, tsb_uid, score: playerScore, time_seconds, youtube_url } = score;
      const response = await fetchWithRefresh('/api/approve-score', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ game_title, email, tsb_uid, score: playerScore, time_seconds, youtube_url })
      });

      if (response.ok) {
        await handleStatusChange(score.id, 'Approved');
        toast.success('Score approved and leaderboard updated.');
      } else {
        const data = await response.json();
        toast.error('Failed to approve score. Please try again.');
      }
    } catch (error) {
      console.error('Error approving score:', error);
      toast.error('Error approving score. Please try again.');
    }
  };

  const handleMark = async (score) => {
    try {
      await handleStatusChange(score.id, 'Marked');
      toast.info('Score marked as suspicious.');
    } catch (error) {
      console.error('Error marking score:', error);
      toast.error('Error marking score. Please try again.');
    }
  };

  const handleReject = async (score) => {
    try {
      await handleStatusChange(score.id, 'Rejected');
      toast.info('Score rejected.');
    } catch (error) {
      console.error('Error rejecting score:', error);
      toast.error('Error rejecting score. Please try again.');
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  return (
    <div className={styles.reviewContainer}>
      <ToastContainer />
      <h2 className={styles.reviewTitle}>Review Submitted Scores</h2>
      <div className={styles.tabs}>
        <button className={`${styles.tabButton} ${selectedTab === 'Pending' ? styles.active : ''}`} onClick={() => setSelectedTab('Pending')}>Pending</button>
        <button className={`${styles.tabButton} ${selectedTab === 'Marked' ? styles.active : ''}`} onClick={() => setSelectedTab('Marked')}>Marked</button>
        <button className={`${styles.tabButton} ${selectedTab === 'Archive' ? styles.active : ''}`} onClick={() => setSelectedTab('Archive')}>Archive</button>
      </div>
      <div className={styles.searchContainer}>
        <input
          type="text"
          placeholder="Search by TSB UserID"
          value={query}
          onChange={handleSearchChange}
          className={styles.searchInput}
        />
        <input
          type="text"
          placeholder="Filter by Game Title"
          value={gameTitle}
          onChange={handleGameTitleChange}
          className={styles.searchInput}
        />
        <button onClick={handleSearch} className={styles.searchButton}>
          Search
        </button>
      </div>

      {/* Desktop Table View */}
      <table className={styles.reviewTable}>
        <thead>
          <tr>
            <th>Game Title</th>
            <th>The Sandbox UserID</th>
            <th>Email</th>
            <th>Score</th>
            <th>Time</th>
            <th>Image</th>
            <th>Video Link</th>
            <th>Timestamp</th>
            {selectedTab === 'Archive' ? (
              <th>Status</th>
            ) : (
              <th>Actions</th>
            )}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(scores) && scores.length > 0 ? (
            scores.map(score => (
              <tr key={score.id}>
                <td>{score.game_title}</td>
                <td>{score.tsb_uid}</td>
                <td>{score.email}</td>
                <td>{score.score}</td>
                <td>{score.time_seconds ? `${Math.floor(score.time_seconds / 60)}:${score.time_seconds % 60}` : 'N/A'}</td>
                <td>
                  <img
                    src={`/${score.image_url}`}
                    alt="User submission"
                    className={styles.thumbnail}
                    onClick={() => handleImageClick(`/${score.image_url}`)}
                  />
                </td>
                <td>
                  {score.youtube_url ? (
                    <a href={score.youtube_url} target="_blank" rel="noopener noreferrer">
                      View Video
                    </a>
                  ) : (
                    'N/A'
                  )}
                </td>
                <td>{formatTimestamp(score.created_at)}</td>
                {selectedTab === 'Archive' ? (
                  <td className={score.status === 'Approved' ? styles.statusApproved : styles.statusRejected}>{score.status}</td>
                ) : (
                  <td>
                    {selectedTab === 'Pending' && (
                      <>
                        <button className={`${styles.button} ${styles.approve}`} onClick={() => handleApprove(score)}>Approve</button>
                        <button className={`${styles.button} ${styles.reject}`} onClick={() => handleReject(score)}>Reject</button>
                        <button className={`${styles.button} ${styles.mark}`} onClick={() => handleMark(score)}>Mark</button>
                      </>
                    )}
                    {selectedTab === 'Marked' && (
                      <>
                        <button className={`${styles.button} ${styles.approve}`} onClick={() => handleApprove(score)}>Approve</button>
                        <button className={`${styles.button} ${styles.reject}`} onClick={() => handleReject(score)}>Reject</button>
                      </>
                    )}
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9">No scores to display.</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Mobile Card View */}
      <div className={styles.mobileScoresList}>
        {Array.isArray(scores) && scores.length > 0 ? (
          scores.map(score => (
            <div key={score.id} className={styles.mobileScoreItem}>
              <div className={styles.mobileScoreDetails}>
                <p><strong>Game:</strong> {score.game_title}</p>
                <p><strong>User:</strong> {score.tsb_uid}</p>
                <p><strong>Email:</strong> {score.email}</p>
                <p><strong>Score:</strong> {score.score}</p>
                <p><strong>Time:</strong> {score.time_seconds ? `${Math.floor(score.time_seconds / 60)}:${score.time_seconds % 60}` : 'N/A'}</p>
                <p><strong>Timestamp:</strong> {formatTimestamp(score.created_at)}</p>
              </div>
              <div className={styles.mobileScoreMedia}>
                <img
                  src={`/${score.image_url}`}
                  alt="User submission"
                  className={styles.mobileThumbnail}
                  onClick={() => handleImageClick(`/${score.image_url}`)}
                />
                {score.youtube_url && (
                  <a href={score.youtube_url} target="_blank" rel="noopener noreferrer" className={styles.mobileVideoLink}>
                    View Video
                  </a>
                )}
              </div>
              <div className={styles.mobileScoreActions}>
                <button className={`${styles.button} ${styles.approve}`} onClick={() => handleApprove(score)}>Approve</button>
                <button className={`${styles.button} ${styles.reject}`} onClick={() => handleReject(score)}>Reject</button>
                <button className={`${styles.button} ${styles.mark}`} onClick={() => handleMark(score)}>Mark</button>
              </div>
            </div>
          ))
        ) : (
          <p className={styles.noScores}>No scores to display.</p>
        )}
      </div>

      {hasMore && (
        <button className={styles.loadMoreButton} onClick={handleLoadMore} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Load More'}
        </button>
      )}
      {selectedImage && (
        <Modal
          isOpen={!!selectedImage}
          onRequestClose={closeModal}
          className={styles.modal}
          overlayClassName={styles.overlay}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
        >
          <img src={selectedImage} alt="Large preview" className={styles.largeImage} />
          <button onClick={closeModal} className={styles.closeButton}>X</button>
        </Modal>
      )}
    </div>
  );
};

export default Review;
